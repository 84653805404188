






































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from "@/api";

@Component({
  name: "mtable",
  components: {}
})

export default class extends Vue {

  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  btnloading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };


  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
            .post("J27173", this.search)
            .then((data) => {
              this.total = data.total;
              this.tableData = data.list;
            })
            .finally(() => {
              this.loading = false;
            });
  }

  // 详情
  detailDialog = false
  detailInfo: any = {}
  statusVal: any = true
  toDetail(rows: any) {
    this.detailInfo = rows
    this.detailDialog = true

  }


  activated() {
    this.searchTable();
  }


}
